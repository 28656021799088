import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { IMenuItem } from '@aecom/core/lib/interfaces/component';
import {
  ContractService,
  TPContract,
  ProjectNavigation,
} from '../api-generated';
import LocalStorageService from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export default class IContractService {
  protected dataSubject = new BehaviorSubject<TPContract[]>([]);

  data$: Observable<TPContract[]> = this.dataSubject.asObservable();

  protected selectedSubject = new BehaviorSubject<TPContract>(null);

  selectedItem$: Observable<TPContract> = this.selectedSubject.asObservable();

  constructor(
    public storage: LocalStorageService,
    public http: HttpClient,
    private contractService: ContractService,
  ) {}

  getAllContract(): Observable<TPContract[]> {
    return this.contractService.getAllContract();
  }

  load(): Observable<TPContract[]> {
    return this.contractService.getAllContract().pipe(
      first(),
      map((data: TPContract[]) => {
        this.dataSubject.next([...data]);

        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  // setSelectedId(id: string): void {
  //   if (this.dataSubject.getValue()) {
  //     const items = this.dataSubject.getValue().filter((c) => c.Guid === id);
  //     if (items && items.length) {
  //       this.selectedSubject.next(items[0]);
  //     } else {
  //       this.selectedSubject.next(null);
  //     }
  //   }
  // }

  public setSelected(
    item: TPContract | IMenuItem | ProjectNavigation | null,
  ): void {
    console.log(`selected contract: ${JSON.stringify(item)}`);

    let contract: TPContract | null = null;
    if (Array.isArray(item) && item.length) {
      contract = (item[0] as unknown) as TPContract;
    } else if (!Array.isArray(item)) {
      contract = (item as unknown) as TPContract;
    }

    if (contract && ((contract as unknown) as { contract: unknown }).contract) {
      contract = ((contract as unknown) as { contract: TPContract }).contract;
    }

    // console.log(`setting contract: ${contract.Name}`);
    this.selectedSubject.next(contract as TPContract);
  }

  // private setDataSubject(data: Contract[]): void {
  //   this.dataSubject.next(data);
  // }
}
