<section class="signout">
  <div></div>

  <div class="singout-content">
    <i class="icon-signout"></i>

    <h1>Sign out Successful</h1>

    <p>For your security we recommend you close your browser.</p>

    <aecom-button class="btn btn-solid blue" (click)="login()">
      Sign in
    </aecom-button>
  </div>

  <p class="copyright">
    © NYC BBJ PROGRAM
  </p>
</section>
