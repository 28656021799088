import { Injectable } from '@angular/core';
import { catchError, first, from, Observable, of } from 'rxjs';
import LoadingService from '../services/loading.service';
import { AuthService } from '@bbj/components';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export default class ErrorResolve {
  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
  ) {}

  resolve(): Observable<string | void > {
    this.loadingService.start();
    return from(this.authService.login()).pipe(
      first(),
      catchError((error: HttpErrorResponse) => {
        return of();
      }),
    );
  }
}
