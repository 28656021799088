<div class="background-container">
    <div class="dp-container">
        <div class="heading">Data Protection Policy</div>
        <ng-container *ngFor="let i of count" >
            <div class="row seperator"  >
                <div class=" col-12 heading-1">Loream Ipsom</div>
                <div class="col-12 dp-content">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Consectetur adipiscing elit ut aliquam purus sit amet luctus. 
                Metus vulputate eu scelerisque felis imperdiet proin fermentum leo. Hendrerit dolor magna eget est. At auctor urna nunc id cursus metus aliquam eleifend mi.
                </div>
                <div class="col-12 dp-content">
                    <ul class="dashed">
                        <li>  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </li>
                        <li>Duis convallis convallis tellus id interdum velit. Mollis aliquam ut porttitor leo a diam sollicitudin tempor id. Cursus turpis massa tincidunt dui ut ornare lectus. Justo donec enim diam vulputate ut pharetra. Sit amet mattis vulputate enim nulla aliquet porttitor lacus luctus. Tincidunt dui ut ornare lectus sit amet.
                        </li>
                        <li>  Sapien et ligula ullamcorper malesuada proin libero nunc consequat. Molestie a iaculis at erat pellentesque.
                        </li>
                        <li>Consequat id porta nibh venenatis cras sed felis eget velit. Amet facilisis magna etiam tempor orci eu. Sed ullamcorper morbi tincidunt ornare massa eget. Sodales ut eu sem integer vitae justo eget magna fermentum.
                        </li>
                        <li>Sollicitudin tempor id eu nisl nunc. Facilisis mauris sit amet massa vitae tortor condimentum. Egestas quis ipsum suspendisse ultrices. Facilisis magna etiam tempor orci eu lobortis elementum. Non quam lacus suspendisse faucibus. Quisque non tellus orci ac auctor. Ac placerat vestibulum lectus mauris ultrices eros in cursus. Egestas sed sed risus pretium quam. Lectus quam id leo in vitae turpis massa. Tempus imperdiet nulla malesuada pellentesque elit eget. 
                        </li>
                        <li>Erat imperdiet sed euismod nisi porta lorem mollis.</li>
                    </ul>
                </div>
            </div>
        </ng-container>
        <div class="button-row">
            <aecom-button innerClass="btn blue btn-icon solid" (click)="refreshPage()"> RETURN HOME </aecom-button>
        </div>
    </div>
</div>
