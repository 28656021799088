import { Injectable } from "@angular/core";
import { catchError, Observable, tap, throwError } from "rxjs";
import axios from 'axios';
import * as moment from 'moment-timezone';
import environment from "src/environments/environment";
import LocalStorageService from "./local-storage.service";
import { IADUser } from "@aecom/core";
import { CalendarService, IUser } from "../api-generated";

@Injectable({
  providedIn: 'root',
})
export default class CalendarLocalService {

  constructor(
    public calendarService: CalendarService,
    public localStorageService: LocalStorageService,
  ) {}

  getEvents(startDate: string, endDate: string): Observable<any> {

    // const uri = environment.api.getCalendar.uri;

    // const startTime = "00:00:00"; // mid night
    // const endTime = "00:00:00"; // mid night
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's computer/browser timezone
    // const numericOffset = moment.tz(timezone).format('Z'); // Converts to `+05:00` format

    // // console.log('timezone', numericOffset);
    // const params =  `/starttime/${startDate}T${startTime}${numericOffset}` + 
    //                 `/endtime/${endDate}T${endTime}${numericOffset}`;
    // // const params = `/starttime/2024-06-01T00:00:00/endtime/2025-05-30T00:00:00"`;

    // const query =
    //   `?api-version=${environment.api.getCalendar.apiVersion}` +
    //   `&sp=${environment.api.getCalendar.sp}` +
    //   `&sv=${environment.api.getCalendar.sv}` +
    //   `&sig=${environment.api.getCalendar.sig}`;

    // const graphEndpoint = `${uri}${params}${query}`;
    
    // return new Observable(observer => {
    //   axios.get(graphEndpoint)
    //     .then(response => {
    //       observer.next(response.data);
    //       observer.complete();
    //     })
    //     .catch(error => {
    //       observer.error(error);
    //     });
    // });

     // Directly return the observable from calendarService and use RxJS operators if necessary
    return this.calendarService.getEvents(startDate, endDate).pipe(
      // Use tap() to inspect or modify the response (if you need to add logic like saving to localStorage, etc.)
      tap((data: any) => {
        return data;
      }),
      // Optionally handle errors here if needed
      catchError((error: any) => {
        console.error('Error fetching events:', error);
        return throwError(() => new Error('Error fetching events: ' + error.message));
      })
    );
    
  }

  getCalendarUsers(): Observable<any> {

    // const uri = environment.api.getADUsers.uri;

    // const query =
    //   `?api-version=${environment.api.getADUsers.apiVersion}` +
    //   `&sp=${environment.api.getADUsers.sp}` +
    //   `&sv=${environment.api.getADUsers.sv}` +
    //   `&sig=${environment.api.getADUsers.sig}`;

    // const graphEndpoint = `${uri}${query}`;

    // return new Observable(observer => {
    //   axios.get(graphEndpoint)
    //     .then(response => {
    //       if (response.status >= 200 && response.status < 300) {

    //         // store data in local storage
    //         response.data.forEach((user: IADUser) => {
    //           this.localStorageService.saveLocalStorage(user.mail, user);
    //         })

    //         observer.next(response.data);
    //         observer.complete();
    //       }
    //     })
    //     .catch(error => {
    //       console.log('response error:', error);
    //       observer.error(error);
    //     });
    // });

    return this.calendarService.getCalendarUsers().pipe(
      tap((data: IUser[]) => {
        // store data in local storage
        data.forEach((user: IUser) => {
          this.localStorageService.saveLocalStorage(String(user.mail).toLowerCase(), user);
        });
      }),
      catchError((error: any) => {
        console.log('response error:', error);
        return throwError(() => new Error('Error fetching calendar users: ' + error.message));
      })
    );      
  }
}
