<!-- HTML -->
<div class="calendar-container">
  

  <!-- Calendar Header -->
  <header class="calendar-header">
    <div class="button-group">
      <aecom-button
          style="margin-left: 10px;"
          innerClass="btn-icon blue solid"
          (click)="todayMonth()"
        >Today</aecom-button
      >
      <button
          style="margin-left: 10px;"
          class="previous-button"
          (click)="prevMonth()"
        > <em
            style="width: 30px; height: 30px;"
            class="icon icon-blue"
            [ngClass]="'icon-action_arrow_left'"
        ></em></button
      >
      <button
          style="margin-left: 0px;"
          class="next-button"
          (click)="nextMonth()"
        > <em
            style="width: 30px; height: 30px"
            class="icon icon-blue"
            [ngClass]="'icon-action_arrow_right'"
        ></em></button
      >
      <h1 class="month-label">{{ selectedMonth | date:'MMMM yyyy' }}</h1>
    </div>
    <div class="button-group view-buttons">
      <button class="nav-button" (click)="viewMonth()">Month</button>
      <button class="nav-button" (click)="viewWeek()">Week</button>
      <button class="nav-button" (click)="viewDay()">Day</button>
    </div>
  </header>

  <!-- Loading Indicator -->
  <div class="loading" *ngIf="isLoading">Loading...</div>

  <div class="loaded" *ngIf="!isLoading">
    <!-- Tooltip outside of .events -->
    <div
      class="event-tooltip"
      [ngClass]="{ 'visible': tooltipVisible }"
      [ngStyle]="{ top: tooltipPosition.y + 'px', left: tooltipPosition.x + 'px' }"
    >
      <span class="event-time">
        {{ hoveredEvent?.startMultipleDays || hoveredEvent?.start | date: 'M/d/yyyy' }} 
        {{ hoveredEvent?.startMultipleDays || hoveredEvent?.start | date: 'shortTime' }} - 
        {{ hoveredEvent?.endMultipleDays   || hoveredEvent?.end   | date: 'M/d/yyyy' }}
        {{ hoveredEvent?.endMultipleDays   || hoveredEvent?.end   | date: 'shortTime' }}
      </span>
      <span class="event-subject">{{ hoveredEvent?.subject }}</span>
    </div>
  
    <!-- Calendar Grid -->
    <div class="calendar-grid">
      <div class="day-of-week-row">
        <div class="day-of-week" *ngFor="let day of daysOfWeek">{{ day }}</div>
      </div>

      <!-- Day Rows with Scrollable Container -->
      <div class="day-row-container">
        <div class="day-row" *ngFor="let week of calendarDays">
          <div  
            class="day-box"
            [ngClass]="{
              'day-past': day?.dayType === 'past', 
              'day-today': day?.dayType === 'today', 
              'day-future': day?.dayType === 'future'
            }"
            *ngFor="let day of week"
          >
            <div class="day-number" *ngIf="day">{{ day.day }}</div>
            <div class="events">
              <div
                class="event"
                *ngFor="let event of getEventsForDay(day); let i = index"
                [class.event-hidden]="i >= 3 && !isExpanded[getDayKey(day)]"
                (click)="showEventDetails(event)"
                (mouseover)="showTooltip(event, $event)"
                (mouseleave)="hideTooltip()"
              >
                <div class="event-item">
                  <ng-container [ngSwitch]="event?.eventType">
                
                    <!-- All Day Event -->
                    <ng-container *ngSwitchCase="CalendarEventType.ALL_DAY">
                      <span class="event-time">All Day</span>
                    </ng-container>
                
                    <!-- Partial Single-Day Event -->
                    <ng-container *ngSwitchCase="CalendarEventType.PARTIAL_SINGLE">
                      <span class="event-time">{{ event?.start | date: 'shortTime' }}</span>
                    </ng-container>
                
                    <!-- Partial Multi-Day Event -->
                    <ng-container *ngSwitchCase="CalendarEventType.PARTIAL_MULTIPLE">
                      <span class="event-time">{{ event?.start | date: 'shortTime' }}</span>
                    </ng-container>
                
                    <!-- Default Case (Optional) -->
                    <ng-container *ngSwitchDefault>
                      <span class="event-time">{{ event?.start | date: 'shortTime' }}</span>
                    </ng-container>
                
                  </ng-container>
                
                  <span class="event-subject">{{ event?.subject }}</span>
                </div>              
              </div>
              <button
                class="more-events-button"
                *ngIf="getEventsForDay(day).length > 3"
                (click)="expandDayBoxToggle(day)"
              >
                <span *ngIf="!isExpanded[getDayKey(day)]">▼</span>
                <span *ngIf="isExpanded[getDayKey(day)]">▲</span>
                {{ getButtonLabel(day) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
