import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@bbj/components';
import environment from "src/environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export default class AppComponent implements OnInit {
  title = 'BBJPORTAL';

  projects;

  mainheight: number;

  isdisabledBtn = true;

  isIframe = false;

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.mainheight = window.innerHeight;
  }

  constructor(
    public activateRoute: ActivatedRoute,
    public authService: AuthService,
    public route: Router,
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.authService.initService(environment.stage, environment.azureNew.tenantId);
    this.mainheight = window.innerHeight;
  }
}
