import { AecomCoreModule, LayoutService } from '@aecom/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";
import { NgxSpinnerModule } from 'ngx-spinner';
import environment from 'src/environments/environment';
import { OAuthSettings } from '../oauth';
import { BASE_PATH } from './api-generated/variables';
import AppRoutingModule from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog'; // Import MatDialogModule
import AppComponent from './app.component';
import AccessDeniedComponent from './content/auth/access-denied/access-denied.component';
import DataPrivacyComponent from './content/data-privacy/data-privacy.component';
import ErrorComponent from './content/error/error.component';
import NavigationComponent from './content/navigation/navigation.component';
import PortalComponent from './content/portal/portal.component';
import SingoutComponent from './content/singout/singout.component';
import ErrorResolve from './resolvers/error-resolver';
import LoginUserResolve from './resolvers/login-user-resolver';
import LocalStorageService from './services/local-storage.service';
import SidenavService from './services/nav.service';
import { StorageKey } from './shared/localstorage';
import LocalTaskService from './services/local-task.service';
import { CalendarComponent } from './content/calendar/calendar.component';
import { CalendarEventDetailDialogComponent } from './content/calendar-event-detail-dialog/calendar-event-detail-dialog.component';
import { AuthInterceptor, AuthService, BBJCoreModule } from "@bbj/components";
import NavService from './services/nav.service';

export function tokenGetter(): string {
  return localStorage.getItem(StorageKey.TOKEN);
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: OAuthSettings.appId,
      authority: OAuthSettings.authority,
      redirectUri: environment.url.redirectUri,
      postLogoutRedirectUri: environment.url.logoutURL,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: OAuthSettings.protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: OAuthSettings.scopes,
    },
    loginFailedRoute: environment.url.redirectUri,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    NavigationComponent,
    PortalComponent,
    DataPrivacyComponent,
    ErrorComponent,
    SingoutComponent,
    CalendarComponent,
    CalendarEventDetailDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    MsalModule,
    BrowserAnimationsModule,
    DragDropModule,
    AecomCoreModule,
    BBJCoreModule,
    MatDialogModule,
  ],
  providers: [
    DatePipe,
    LocalStorageService,
    AuthService,
    NavService,
    SidenavService,
    LayoutService,
    ErrorResolve,
    LoginUserResolve,
    LocalTaskService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    { provide: BASE_PATH, useValue: environment.baseWebAPI },
    MsalBroadcastService,
    MsalService,
    MsalGuard,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
