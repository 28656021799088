/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable security/detect-object-injection */
import { Injectable } from '@angular/core';
import { Observable, forkJoin, from, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { NavigationService, TaskService, UserService } from '../api-generated/api/api';
import NavService from '../services/nav.service';
import ContractService from '../services/contract.service';
import LoadingService from '../services/loading.service';
import { INavigationRoot } from '../api-generated/model/iNavigationRoot';
import LocalTaskService from '../services/local-task.service';
import { ITaskTab } from '../api-generated';
import { AuthService } from '@bbj/components';

@Injectable()
export default class LoginUserResolve {
  loginAttempts = 0;

  constructor(
    private contractService: ContractService,
    private loadingService: LoadingService,
    private authService: AuthService,
    private navService: NavService,
    private navigationService: NavigationService,
    public localTaskService: LocalTaskService,
    public taskService: TaskService,
    private userService: UserService,
  ) {}

  resolve(): Observable<void> {
    this.loadingService.start();
    return from(this.authService.login()).pipe(
      switchMap((userId) => {
        if (!userId) {
          return of();
        }
        return this.userService.getUser(userId);
      }),
      switchMap((loginUser) => {
        if (!loginUser) {
          return of();
        }
        const permission = this.authService.getPermission();
        this.navService.setUserItem(loginUser.givenName, loginUser.surname, loginUser.id, loginUser.mail, loginUser.companyName, permission);
        return this.onLoggedIn();
      }),
      first(),
    );
  }

  onLoggedIn(): Observable<void> {
    return forkJoin([
      this.navigationService.getAllMenus('portal'),
      this.taskService.getTasks(),
    ]).pipe(
      map(([root, tasks]: [INavigationRoot, ITaskTab[]]) => {
        this.navService.setBroughData(root.boroughNavigation);
        this.navService.setGlobalData(root.globalNavigation);
        this.navService.setDataSubject(root.combinedBoroughs);
        this.localTaskService.setItems(tasks);
        if (root.globalNavigation.length) {
          this.navService.setSelectedId(
            root.globalNavigation[0].globalModule.Guid,
          );
        } else if (root.boroughNavigation.length) {
          this.navService.setSelectedId(
            root.boroughNavigation[0].boroughItem.Guid,
          );

          this.contractService.setSelected(
            root.boroughNavigation[0].projects[0],
          );
        }
        return;
      }),
    );
  }
}
