<div class="image" *ngIf="baseService.selectedItem$ | async as selectedBorough">
  <div class="manu-bar">
    <div class="manu-borough">
      <ng-container *ngFor="let manu of boroughList">
        <div
          class="manu-option"
          [ngClass]="{
            'manu-selected': selectedBorough.Guid === manu.boroughItem.Guid
          }"
          (click)="boroughSelect(manu.boroughItem)"
        >
          <img class="manu-icon" src="{{ getBoroughIcon(manu.boroughItem) }}" />

          <div class="manu-text">
            <span>{{ manu.boroughItem.Name }}</span>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="manu-global">
      <ng-container *ngFor="let manu of globalList">
        <div
          class="manu-option"
          [ngClass]="{
            'manu-selected': selectedBorough.Guid === manu.globalModule.Guid
          }"
          (click)="boroughSelect(manu.globalModule)"
        >
          <img
            class="manu-icon"
            src="{{ getBoroughIcon(manu.globalModule) }}"
          />

          <div class="manu-text">
            <span>{{ manu.globalModule.Code }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="stage === 'staging'" class="warningbanner">This Beta is for training purposes only. If you need technical support, please email <a href = "mailto: dc@bbjnyc.com">dc@bbjnyc.com</a> or click Support below.</div>
  <div
    class="main-body"
    *ngIf="
      selectedBorough.Guid !== 'f5960107-e029-11eb-a7b4-ace2d34b68ef' &&
      selectedBorough.Guid !== 'f5960486-e029-11eb-a7b4-ace2d34b68ef'
    "
  >
    <div class="row" style="height: 100%; overflow: auto">
      <div class="col-md-4" style="padding-right: 40px; padding-left: 120px">
        <div class="row main-left-section">
          <div class="col px-4">
            <div class="sub-title-row">
              <img
                class="sub-title-icon"
                src="{{ getBoroughIcon(selectedBorough) }}"
              />

              <div class="sub-title-text">
                <span>{{ selectedBorough.Name }}</span>
              </div>
            </div>

            <div 
              *ngIf="displayCalendar"
              class="application-name" 
            >{{calendarApplication?.applicationName}}</div>
            <div
              style="width: 100%; position: relative"
              *ngIf="showProjectDropDown"
            >
              <div
                class="projectDropDown w-100 px-4"
                style="margin-top: 15px; display: flex"
                (click)="toggleProjectDropDown()"
              >
                <span class="pddText">
                  {{
                    selectedProjectName !== ''
                      ? selectedProjectName
                      : 'Select Contract'
                  }}
                </span>

                <span class="pddAngleDown ml-auto">
                  <em
                    style="width: 30px; height: 30px"
                    class="icon icon-blue"
                    [ngClass]="
                      pddOpen
                        ? 'icon-action_arrow_up'
                        : 'icon-action_arrow_down'
                    "
                  ></em>
                </span>
              </div>

              <div
                class="w-100"
                style="position: absolute; z-index: 55"
                [ngStyle]="{ display: pddOpen ? 'block' : 'none' }"
              >
                <ng-container *ngFor="let project of projectList">
                  <div
                    class="projectDropDownSelection w-100 px-4"
                    (click)="projectSelect(project.contractItem)"
                  >
                    <span>{{ project.contactName }}</span>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8" style="padding-right: 120px; padding-left: 40px">
        <div 
          class="row main-right-section"
          [ngClass]="{ 'calendar': displayCalendar }"
        >
          <ng-container *ngIf="!displayCalendar">
            <ng-container *ngFor="let application of applicationList">
              <div class="col-lg-3 col-md-4 col-sm-6 col-6 p-2">
                <div class="row mx-0">
                  <!-- <a
                    href="{{
                      application.url ? application.url : '/error/comingSoon'
                    }}"
                    target="_blank"
                    rel="noopener"
                    class="col cornerBox"
                  > -->
                  <a
                    (click)="openApplication($event, application)"
                    href="javascript:void(0)"
                    rel="noopener"
                    class="col cornerBox"
                  >
                    <div class="applicationBox">
                      <div>
                        <img
                          class="sub-title-icon"
                          src="{{ getApplicationIcon(application) }}"
                        />
                        <!-- <div [ngClass]="getApplicationIconClass(application)" class="icon icon-xl"></div> -->
                      </div>
  
                      <div class="applicationTitle">
                        <span>{{ application.applicationName }}</span>
                      </div>
                      <!-- <div
                        class="px-3 py-2"
                        *ngIf="
                          application.applicationName !== 'Workspace' &&
                          application.role !== ''
                        "
                      >
                        <span class="applicationRole">{{
                          application.role
                        }}</span>
                      </div> -->
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="displayCalendar">
            <app-calendar></app-calendar>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="foot-row">
      <div class="foot-left-section">
        <p>
          © NYC BBJ PROGRAM
        </p>
      </div>

      <div class="foot-right-section">
        <div class="labelBox">
          <ng-container *ngFor="let item of contactList">
            <div >{{ item.Name }}</div>
          </ng-container>
        </div>

        <div class="emailBox">
          <ng-container *ngFor="let item of contactList">
            <div>
              <a [href]="'mailto:' + item.Email">
                {{ item.Email }}
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div
    class="dashboard"
    *ngIf="
      selectedBorough.Embed
    "
  >
    <iframe
      [src]="makeUrlSafe(selectedBorough.UrlTemplate)"
      allow="geolocation; microphone; camera"
    ></iframe>
  </div>
</div>
