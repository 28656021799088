import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import LoadingService from 'src/app/services/loading.service';

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss'],
})
export default class DataPrivacyComponent implements OnInit {
  count = [1, 2, 3, 4];

  constructor(private loadingService: LoadingService, private router: Router) {}

  ngOnInit(): void {
    this.loadingService.stop();
  }

  refreshPage(): void {
    this.router.navigate(['/']);
  }
}
