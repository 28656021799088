import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarEventType, ICalendarEvent } from '../calendar/calendar.component';
import LocalStorageService from '@services/local-storage.service';
import { IADUser } from '@aecom/core';

@Component({
  selector: 'app-event-detail-dialog',
  templateUrl: './calendar-event-detail-dialog.component.html',
  styleUrls: ['./calendar-event-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CalendarEventDetailDialogComponent {

  sanitizedBody: SafeHtml;
  organizer: IADUser;
  requiredAttendeesList: IADUser[];
  optionalAttendeesList: IADUser[];
  public CalendarEventType = CalendarEventType;
  
  constructor(
    public dialogRef: MatDialogRef<CalendarEventDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public event: ICalendarEvent,
    private sanitizer: DomSanitizer,
    private localStorageService: LocalStorageService
  ) {
    this.sanitizedBody = this.sanitizeHTMLBody(event.body);

    const organizer: IADUser = {
      displayName: event.organizer,
      givenName: "",
      jobTitle: "",
      mail: "",
      mobilePhone: "",
      officeLocation: "",
      preferredLanguage: "",
      surname: "",
      id: "",
      userPrincipalName: "",
      HostURL: "",
      roles: []
    };
    const key = String(event.organizer).toLowerCase();
    const storedOrganizer = this.localStorageService.getLocalStorage(key);
    this.organizer = Array.isArray(storedOrganizer) && storedOrganizer.length === 0 
      ?  organizer
      : storedOrganizer as IADUser;

    this.requiredAttendeesList = this.splitAttendees(event.requiredAttendees)
      .map((attendee: string) => {
        const user: IADUser = {
          displayName: attendee,
          givenName: "",
          jobTitle: "",
          mail: "",
          mobilePhone: "",
          officeLocation: "",
          preferredLanguage: "",
          surname: "",
          id: "",
          userPrincipalName: "",
          HostURL: "",
          roles: []
        };
        const key = String(attendee).toLowerCase();
        const storedUser = this.localStorageService.getLocalStorage(key);
        return Array.isArray(storedUser) && storedUser.length === 0 
          ? user 
          : storedUser as IADUser;
      });

    this.optionalAttendeesList = this.splitAttendees(event.optionalAttendees)
      .map((attendee: string) => {
        const user: IADUser = {
          displayName: attendee,
          givenName: "",
          jobTitle: "",
          mail: "",
          mobilePhone: "",
          officeLocation: "",
          preferredLanguage: "",
          surname: "",
          id: "",
          userPrincipalName: "",
          HostURL: "",
          roles: []
        };
        const key = String(attendee).toLowerCase();
        const storedUser = this.localStorageService.getLocalStorage(key);
        return Array.isArray(storedUser) && storedUser.length === 0 
          ? user 
          : storedUser as IADUser;
      });
    
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  sanitizeHTMLBody(body: string): SafeHtml {
    if (!body) {
      return ''; // Return an empty string if the body is undefined or empty
    }
    // Sanitize the cleaned body
    return this.sanitizer.bypassSecurityTrustHtml(body);
  }

  splitAttendees(attendees: string): string[] {
    return attendees ? attendees.split(';').filter(email => email.trim().length > 0) : [];
  }
}
